import * as _defineProperties2 from "define-properties";
var _defineProperties = _defineProperties2;
try {
  if ("default" in _defineProperties2) _defineProperties = _defineProperties2.default;
} catch (e) {}
import * as _callBind2 from "call-bind";
var _callBind = _callBind2;
try {
  if ("default" in _callBind2) _callBind = _callBind2.default;
} catch (e) {}
import _implementation from "./implementation";
import _polyfill from "./polyfill";
import _shim from "./shim";
var exports = {};
var defineProperties = _defineProperties;
var callBind = _callBind;
var implementation = _implementation;
var getPolyfill = _polyfill;
var shim = _shim;
var polyfill = callBind.apply(getPolyfill());
// eslint-disable-next-line no-unused-vars
var bound = function assign(target, source1) {
  return polyfill(Object, arguments);
};
defineProperties(bound, {
  getPolyfill: getPolyfill,
  implementation: implementation,
  shim: shim
});
exports = bound;
export default exports;